import { AppBar, Box, Container, Toolbar } from '@mui/material';
import React from 'react';
import Link from './Link';
import HCLogo from './../images/logo/logo.svg';

const sections = [
  { id: 'services', label: 'What we do' },
  { id: 'about', label: 'About' },
  { id: 'contact', label: 'Contact' }
];

const Header = () => {
  return (
    <AppBar
      position="relative"
      color='transparent'
      sx={{ boxShadow: 'none', pt: 2, zIndex: 1 }}
    >
      <Container maxWidth="none">
        <Toolbar disableGutters sx={{
          justifyContent: { xs: 'center', md: 'flex-end' },
          flexWrap: 'wrap-reverse',
          gap: 2,
          pr: { lg: 11 },
        }}>
          <Box sx={{ overflowX: 'hidden' }}>
            {sections.map((section, i) => (
              <Link
                sx={{
                  mx: { xs: 0, md: 1 },
                  px: { xs: 0.5, md: 1 },
                  py: { xs: 0.5, md: 2 },
                  textTransform: 'uppercase',
                  fontSize: { xs: '0.725rem', md: 'inherit' },
                  animation: `${0.2 * (i + 1)}s backwards ease-out 1.8s appearFromRight`,
                  position: 'relative',
                  display: 'inline-block',
                  textDecoration: 'none',
                  willChange: 'transform',
                  '&:not(:first-of-type):before': {
                    content: '"/"',
                    display: 'inline-block',
                    mr: '6px',
                    position: { md: 'absolute' },
                    right: { md: '100%' },
                  }
                }}
                key={section.id}
                to={`/#${section.id}`}
              >{section.label}</Link>
            ))}
          </Box>
          <Link to='/' sx={{
            borderRadius: '50%',
            boxShadow: '0 2px 10px 2px #000',
            width: 50,
            height: 50,
            animation: '.8s backwards linear .5s rotateGrowing',
            willChange: 'transform',
            display: 'block',
            transition: 'all 0.2s',
            p: 0,
            textDecoration: 'none',
            WebkitTapHighlightColor: 'transparent',
            '&:after': { display: 'none' },
            '&:hover': { boxShadow: '0 1px 10px 4px #000', transform: 'scale(1.1)' },
            '&:active': { boxShadow: 'none', transform: 'scale(0.9)' }
          }}>
            <HCLogo
              title='HC Tech center logo made up of the HC initials inside a black circle'
              width={50}
              height={50}
            />
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;