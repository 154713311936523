import { Box, Container } from '@mui/material';
import React from 'react';
import Copyright from './Copyright';
import HCLogo from './../images/logo/logo.svg';

const Footer = () => {
  return (
    <Container
      component='footer'
      sx={{
        position: 'relative',
        textAlign: 'center',
        py: 3,
        backgroundColor: 'background.paper',
        color: 'primary.contrastText',
        minHeight: '30vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        gap: 2,
      }}
      maxWidth='none'
    >
      <Box sx={{
        borderRadius: '50%',
        boxShadow: '0 0 8px 0 #000',
        zIndex: 1,
        width: 50,
        height: 50,
      }}>
        <HCLogo
          title='HC Tech center logo made up of the HC initials inside a black circle'
          width={50}
          height={50}
        />
      </Box>

      <Copyright color='primary.contrastText' />

      <Box sx={{
        position: 'absolute',
        width: '100%',
        minWidth: 700,
        height: '25vh',
        left: 0,
        bottom: 0,
        zIndex: 0,
        color: 'primary.light'
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="contact-wave"
          viewBox="0 0 800 338.05"
          preserveAspectRatio="none"
          fill='currentColor'
          style={{
            transform: 'rotateX(180deg)',
            transformOrigin: '50% 50%',
            height: '100%',
            width: '100%',
            display: 'block',
          }}
        >
          <path>
            <animate
              attributeName="d"
              values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z"
              repeatCount="indefinite"
              dur="25s"
            />
          </path>
        </svg>
      </Box>
    </Container>
  );
};

export default Footer;