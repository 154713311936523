import { lime, red } from '@mui/material/colors';
import { createTheme as muiCreateTheme } from '@mui/material/styles';

export const primaryLight = '#97e9f9';
export const primaryMain = '#41dffd';
export const primaryDark = '#03a2c0';
export const contrastText = '#121212';
export const textPrimaryLight = '#121212';
export const textPrimaryDark = '#dcf9ff';

const headingFontFamily = [
  'Bungee',
  'cursive',
].join(',');
const headingSx = {
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: headingFontFamily,
};

export const createTheme = (mode = 'light') => {
  return muiCreateTheme({
    typography: {
      fontSize: 12,
      fontFamily: [
        '\'Unbounded\'',
        'Adjusted Arial Fallback'
      ].join(','),
      h1: headingSx,
      h2: {
        ...headingSx,
        filter: 'drop-shadow(2px 3px 0px color-mix(in srgb, var(--color) 70%, transparent))',
      },
      h3: headingSx,
      h4: headingSx,
      h5: headingSx,
      h6: headingSx,
    },
    palette: {
      mode,
      primary: {
        light: primaryLight,
        main: primaryMain,
        dark: primaryDark,
        contrastText
      },
      secondary: {
        light: lime[200],
        main: lime[500],
        dark: lime[700],
        contrastText
      },
      error: {
        main: red.A400,
      },
      text: {
        primary: mode === 'light' ? textPrimaryLight : textPrimaryDark,
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            '--glow-color': mode === 'light' ? primaryMain : primaryLight,
            backgroundColor: 'var(--glow-color)',
            '&.Mui-disabled': {
              border: 'none',
            },
            border: '3px solid var(--glow-color)',
            boxShadow: '0px 0px 12px 0px var(--glow-color)',
            '&:hover': {
              boxShadow: '0px 0px 16px 3px var(--glow-color)',
              backgroundColor: mode === 'light' ? primaryDark : primaryMain,
              border: `3px solid ${mode === 'light' ? primaryDark : primaryMain}`,
            },
            '&:focus-visible': {
              backgroundColor: mode === 'light' ? primaryDark : primaryMain,
            },
            '&:active': {
              transition: 'none',
              boxShadow: 'none',
            },
          },
          outlinedPrimary: {
            '--glow-color': mode === 'light' ? primaryMain : primaryLight,
            color: mode === 'light' ? contrastText : primaryLight,
            backgroundColor: 'transparent',
            border: '3px solid var(--glow-color)',
            boxShadow: 'inset 0px 0px 12px 0px var(--glow-color), 0px 0px 12px 0px var(--glow-color)',
            '&:hover': {
              boxShadow: 'inset 0px 0px 16px 3px var(--glow-color), 0px 0px 16px 3px var(--glow-color)',
              backgroundColor: 'var(--glow-color)',
              color: '#121212',
              border: '3px solid var(--glow-color)',
            },
            '&:focus-visible': {
              backgroundColor: primaryMain,
              color: '#121212',
            },
            '&:active': {
              transition: 'none',
              color: '#121212',
              boxShadow: 'none',
            },
          },
          root: {
            fontWeight: 'bold',
            padding: '12px 24px',
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecorationColor: lime[200],
            color: mode === 'light' ? contrastText : primaryMain,
            '--color': mode === 'light' ? contrastText : primaryMain,
            position: 'relative',
            transition: 'color 0.15s',
            padding: '4px 8px',
            '&:after': {
              position: 'absolute',
              content: '""',
              bottom: 5,
              left: 0,
              width: '100%',
              height: 2,
              borderRadius: 5,
              transition: 'transform 0.15s',
              transform: 'scaleY(0)',
              transformOrigin: 'center bottom',
              backgroundColor: mode === 'light' ? primaryDark : primaryLight,
              zIndex: -1,
            },
            '&:hover, &:focus': {
              textDecoration: 'none',
              color: mode === 'light' ? primaryDark : primaryLight,
              '&:after': {
                transformOrigin: 'center middle',
                transform: 'scale(1)',
                transition: 'transform 0.3s',
              }
            }
          }
        }
      }
    }
  });
};