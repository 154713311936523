/* eslint-disable no-undef */
import * as React from 'react';
import MuiLink from '@mui/material/Link';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

const Link = React.forwardRef(function Link (props, ref) {

  const onClick = async (event) => {
    if (typeof window === 'undefined') return;

    // ? https://developer.chrome.com/docs/web-platform/view-transitions/same-document#animating-with-javascript
    // Fallback for browsers that don't support this API:
    if (!document.startViewTransition) {
      return;
    }

    // Get the click position, or fallback to the middle of the screen
    const x = event?.clientX ?? window.innerWidth / 2;
    const y = event?.clientY ?? window.innerHeight / 2;
    // Get the distance to the furthest corner
    const endRadius = Math.hypot(
      Math.max(x, window.innerWidth - x),
      Math.max(y, window.innerHeight - y)
    );

    document.documentElement.style.scrollBehavior = 'initial';
    // With a transition:
    const transition = document.startViewTransition();

    // Wait for the pseudo-elements to be created:
    transition.ready.then(() => {
      // Animate the root's new view
      document.documentElement.animate(
        {
          clipPath: [
            `circle(0 at ${x}px ${y}px)`,
            `circle(${endRadius}px at ${x}px ${y}px)`,
          ],
        },
        {
          duration: 300,
          easing: 'ease-in',
          // Specify which pseudo-element to animate
          pseudoElement: '::view-transition-new(root)',
        }
      );
    });
    await transition.finished;
    document.documentElement.style.scrollBehavior = 'smooth';
  };

  return <MuiLink onClick={onClick} component={GatsbyLink} ref={ref} {...props} to={props.href || props.to} />;
});

Link.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};

export default Link;
