import './../../src/global.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../../src/theme';
import Footer from '../../src/components/footer';
import Header from '../../src/components/header';

export default function TopLayout (props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme(prefersDarkMode ? 'dark' : 'light'),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline enableColorScheme />
      <Header />
      {props.children}
      <Footer />
    </ThemeProvider>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

// ? Docs: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
export function Head () {
  return (
    <>
      <html lang="en" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <title>Tech Center CY</title>
      <meta name='description' content="Tech Center Cyprus specialises in Software, offering consulting services for your Web, Mobile Application or Cloud needs." />
      <meta name='keywords' content='seo, search engine optimization, web services, web development, website services, app services, mobile app, mobile app development, cloud services, software services, software development, software company, cyprus software, tech company, tech center cyprus' />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />

      <link href="https://fonts.googleapis.com/css2?family=Unbounded:wght@400;700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Bungee&display=block" rel="stylesheet"></link>
    </>
  );
}