import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Copyright ({ color = 'text.secondary' }) {
  return (
    <Typography variant="body2" color={color} align="center" sx={{ zIndex: 1 }}>
      {`Copyright © ${process.env.GATSBY_SITE_NAME} ${new Date().getFullYear()}`}
    </Typography>
  );
}

Copyright.propTypes = {
  color: PropTypes.string
};