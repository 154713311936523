exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-js": () => import("./../../../src/pages/ai.js" /* webpackChunkName: "component---src-pages-ai-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-cloud-js": () => import("./../../../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-site-test-js": () => import("./../../../src/pages/site-test.js" /* webpackChunkName: "component---src-pages-site-test-js" */),
  "component---src-pages-web-js": () => import("./../../../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */)
}

